@import "~antd/dist/antd.css";

.text-center {
  text-align: center;
}

footer.hr {
  margin-bottom: 10px;
}

.content-full-height {
  min-height: 100vh;
}

.logo {
  float: left;
  width: 180px;
}

.loading {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -16px;
  margin-left: -17px;
}

.ant-alert {
  margin: 10px;
}

.content-margin {
  margin: 10px;
}
